import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import FunFact02 from "../../components/FunFactElements/FunFact02";
import { GetFunfacts } from "../AboutContainers/actions";

const FunFacts = (props) => {
  const [data, setData] = useState([]);
  const [start, setStart] = useState(false);
  const myRef = useRef(null);

  useEffect(() => {
    const GetData = () => {
      GetFunfacts({
        callback: (data) => {
          setData(data?.data);
        },
      });
    };
    GetData();
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      var a = 0;
      $(window).on("scroll", function () {
        var oTop = $("#funfacts").offset()?.top - window.innerHeight;
        if (a == 0 && $(window).scrollTop() > oTop) {
          setStart(true);

          a = 1;
        }
      });
    }
  }, [data]);

  return (
    <section className={props.className} id="funfacts" ref={myRef}>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="funfacts_inner justify-content-around">
              {data?.map((item) => (
                <FunFact02 start={start} key={item.id} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFacts;
