import React from "react";
import { useState, useEffect } from "react";
import { GetSettings } from "../../containers/AboutContainers/actions";
import { Link } from "react-router-dom";
const AsideInfo = () => {
  const [data, setData] = useState([]);

  const GetData = () => {
    GetSettings({
      callback: (data) => {
        setData(data?.data);
      },
    });
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <div className="aside_info_wrapper">
      <button className="aside_close" aria-label="Close Sidebar">
        <i className="bi bi-x-lg"></i>
      </button>
      <div className="aside_logo">
        <Link to="/" className="light_logo">
          <img src="images/logo-light.png" alt="logo" />
        </Link>
        <Link to="/" className="dark_logo">
          <img src="images/logo-dark.png" alt="logo" />
        </Link>
      </div>
      <div className="aside_info_inner">
        {/* <p>Mrittik Architects is a full-service design firm providing architecture architecture.</p> */}

        <div className="aside_info_inner_box">
          <h5>CONTACT INFO</h5>

          {data && data.contact_phone && (
            <>
              <p>
                {" "}
                <Link to={`tel:${data.contact_phone}`}>
                  {data.contact_phone}
                </Link>
              </p>
            </>
          )}

          {data && data.contact_email && (
            <>
              <p>
                {" "}
                <Link to={`mailto:${data.contact_email}`}>
                  {data.contact_email}
                </Link>
              </p>
            </>
          )}
          <h5>CONTACT ADDRESS</h5>
          {data && data.contact_address && (
            <>
              <h6>{data.contact_address}</h6>
            </>
          )}
        </div>
        <div className="social_sites">
          <ul className="d-flex align-items-center justify-content-center">
            {data && data.fb_link && (
              <li className="facebook">
                <Link to={data.fb_link} aria-label="Visit our Facebook page">
                  <i className="bi bi-facebook"></i>
                </Link>
              </li>
            )}

            {data && data.twitter_link && (
              <li className="twitter">
                <Link
                  to={data.twitter_link}
                  aria-label="Visit our Twitter page"
                >
                  <i className="bi bi-twitter"></i>
                </Link>
              </li>
            )}

            {data && data.insta_link && (
              <li className="instagram">
                <Link
                  to={data.insta_link}
                  aria-label="Visit our Instagram page"
                >
                  <i className="bi bi-instagram"></i>
                </Link>
              </li>
            )}
            {data && data.linkedin_link && (
              <li className="linkendin">
                <Link
                  to={data.linkedin_link}
                  aria-label="Visit our Linkedin page"
                >
                  <i className="bi bi-linkedin"></i>
                </Link>
              </li>
            )}
            {data && data.pinterest_link && (
              <li className="pinterest">
                <Link
                  to={data.pinterest_link}
                  aria-label="Visit our Pinterest page"
                >
                  <i className="bi bi-pinterest"></i>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AsideInfo;
