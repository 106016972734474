import $ from "jquery";
import React, { useEffect, useState } from "react";
import SectionGridLines from "../../../components/SectionGridLines";
import { GetSettings } from "../../../containers/AboutContainers/actions";
import logolight from "../../../assets/images/logo-light.png";
import { Link } from "react-router-dom";
const Footer = () => {
  const [data, setData] = useState([]);

  const GetData = () => {
    GetSettings({
      callback: (data) => {
        setData(data?.data);
      },
    });
  };

  useEffect(() => {
    GetData();
  }, []);

  useEffect(() => {
    // Totop Button
    $(".totop a").on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });

    // Hide header on scroll down
    const nav = document.querySelector(".header");
    const scrollUp = "top-up";
    let lastScroll = 800;

    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 800) {
        nav.classList.remove(scrollUp);
        $(".totop").removeClass("show");
        return;
      }

      if (currentScroll > lastScroll) {
        // down
        nav.classList.add(scrollUp);
        $(".totop").addClass("show");
      } else if (currentScroll < lastScroll) {
        // up
        nav.classList.remove(scrollUp);
        $(".totop").removeClass("show");
      }
      lastScroll = currentScroll;
    });
  }, []);
  const currentYear = new Date().getFullYear();

  const styles = {
    title: {
      color: " #ffca08",
      fontWeight: 400,
      lineHeight: "2.125rem",
    },
    subtitle: {
      color: "#ffffff",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "2.25rem",
      wordBreak: "break-all",
    },
  };

  return (
    <>
      <footer className="footer bg-dark-200 box_padding">
        <div className="footer_inner bg-black">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-4 col-md-2 col-sm-2">
                <div className="section-header">
                  <h2 className="text-white">Contact</h2>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-5">
                <div className="communication">
                  {/* <div className="info_body"
                                    >
                                        <h6>Studio Website</h6>
                                        <h5></h5>
                                    </div> */}
                  <div className="info_body">
                    {data && data.contact_email && (
                      <>
                        <p style={styles.title}>Email Address</p>
                        <Link
                          to={`mailto:${data.contact_email}`}
                          style={{ color: "white" }}
                          aria-label="Contact us by email"
                        >
                          {data.contact_email}
                        </Link>
                      </>
                    )}
                  </div>
                  <div className="info_body">
                    {data && data.contact_phone && (
                      <>
                        <p style={styles.title}>Phone No</p>
                        <p style={styles.subtitle}>
                          <Link
                            to={`tel:${data.contact_phone}`}
                            style={{ color: "white" }}
                            aria-label="Call us by mobile"
                          >
                            {data.contact_phone}
                          </Link>{" "}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="info_body">
                    {data && data.phone && (
                      <>
                        <p style={styles.subtitle}>
                          <Link
                            to={`tel:${data.phone}`}
                            style={{ color: "white" }}
                            aria-label="Call us by mobile"
                          >
                            {data.phone}
                          </Link>{" "}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="info_body">
                    {data && data.contact_address && (
                      <>
                        <p style={styles.title}>Contact Address</p>
                        <p style={styles.subtitle}>{data.contact_address}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-5">
                <div className="footer_elements d-flex align-items-center justify-content-end">
                  <div className="footer_elements_inner">
                    <div className="footer_logo">
                      <a
                        href="/"
                        className="light_logo"
                        aria-label="go back home"
                      >
                        <img src={logolight} alt="logo" />
                      </a>
                    </div>
                    <div className="footer_social">
                      <ul className="social_list">
                        {data && data.fb_link && (
                          <li className="facebook">
                            <Link
                              to={data.fb_link}
                              aria-label="Visit our facebook"
                            >
                              <i className="bi bi-facebook"></i>
                            </Link>
                          </li>
                        )}

                        {data && data.twitter_link && (
                          <li className="twitter">
                            <Link
                              to={data.twitter_link}
                              aria-label="Visit our twitter"
                            >
                              <i className="bi bi-twitter"></i>
                            </Link>
                          </li>
                        )}

                        {data && data.insta_link && (
                          <li className="instagram">
                            <Link
                              to={data.insta_link}
                              aria-label="Visit our instagram"
                            >
                              <i className="bi bi-instagram"></i>
                            </Link>
                          </li>
                        )}
                        {data && data.linkedin_link && (
                          <li className="linkendin">
                            <Link
                              to={data.linkedin_link}
                              aria-label="Visit our linkedin"
                            >
                              <i className="bi bi-linkedin"></i>
                            </Link>
                          </li>
                        )}
                        {data && data.pinterest_link && (
                          <li className="pinterest">
                            <Link
                              to={data.pinterest_link}
                              aria-label="Visit our pinterest"
                            >
                              <i className="bi bi-pinterest"></i>
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="copyright">
                      {/* <ul>
                                                {data && data.terms_and_conditions &&
                                                    <li><a dangerouslySetInnerHTML={{ __html: data.terms_and_conditions }}></a></li>

                                                }
                                                {data && data.privacy_policy &&
                                                    <li><a dangerouslySetInnerHTML={{ __html: data.privacy_policy }}></a></li>
                                                }
                                            </ul> */}

                      <p>
                        &copy; {currentYear} Artshoc, developed by{" "}
                        <Link
                          to="https://www.compu-vision.me/"
                          target="_blank"
                          style={{
                            color: "white",
                            textDecoration: "underline",
                          }}
                        >
                          COMPU-VISION
                        </Link>
                      </p>
                    </div>
                    {/* <div className="copyright"
                                        >
                                            <p>Mrittik 2023. All Rights Reserved</p>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SectionGridLines />
        </div>
      </footer>

      <div className="totop">
        <a href="#">UP</a>
      </div>
    </>
  );
};

export default Footer;
