import React, { useEffect, useState } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderCard08 from "../../components/SliderElements/SliderCard08";
import { GetSlide } from "../../Pages/HomePages/actions";

const Sliders09 = () => {
  const [data, setData] = useState([]);

  const GetData = () => {
    GetSlide({
      callback: (data) => {
        setData(data?.data);
      },
    });
  };

  useEffect(() => {
    GetData();
  }, []);

  return data.length > 0 ? (
    <div className="theme_slider_8 p-0">
      <div className="swiper swiper_theme_slider_7">
        <Swiper
          modules={[Navigation]}
          slidesPerView={1}
          loop={true}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
        >
          {data.map((data) => (
            <SwiperSlide key={data.id}>
              <SliderCard08 item={data} />
            </SwiperSlide>
          ))}
        </Swiper>

        {/* <!-- Add Buttons --> */}
        <div className="swiper-button-prev details_link l-dir pagination-previous">
          <a href="#" aria-label="previous slide">
            <span className="link_icon">
              <span className="line"></span> <span className="circle"></span>
              <span className="dot"></span>
            </span>
          </a>
        </div>
        <div className="swiper-button-next details_link r-dir pagination-next">
          <a href="#" aria-label="next slide">
            <span className="link_icon">
              <span className="line"></span> <span className="circle"></span>
              <span className="dot"></span>
            </span>
          </a>
        </div>
      </div>
    </div>
  ) : null;
};

export default Sliders09;
