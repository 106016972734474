import React from "react";
import ServiceCard from "../components/ServiceCard";
import FormContainer from "../containers/FormContainer";
import { useState, useEffect } from "react";
import { GetServices } from "../Pages/HomePages/actions";
import TeamContainer02 from "./TeamContainers/TeamContainer02";
const ServiceContainer01 = () => {
  const [data, setData] = useState([]);

  const GetData = () => {
    GetServices({
      callback: (data) => {
        setData(data?.data);
      },
    });
  };

  useEffect(() => {
    window.scroll(0, 0);
    GetData();
  }, []);

  return (
    <main className="wrapper">
      <section className="services inner in-service pb-0">
        <div className="container">
          <div className="row">
            <div className="service_wrap">
              {data?.map((item) => (
                <ServiceCard key={item.id} item={item} />
              ))}
            </div>

            {/* <TeamContainer02 /> */}
          </div>
        </div>
      </section>

      {/* <Testimoals /> */}
      <FormContainer />
    </main>
  );
};

export default ServiceContainer01;
